//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    approveLoading: {
      type: Boolean,
      default: false,
    },
    movieTitle: {
      type: String,
    },
    declineLoading: {
      type: Boolean,
      default: false,
    },
    id: {
      type: Number,
    },
    role: {
      type: Object,
    },
    user: {
      type: Object,
    },
  },
};
