import { render, staticRenderFns } from "./MovieRequest.vue?vue&type=template&id=30cb3462&"
import script from "./MovieRequest.vue?vue&type=script&lang=js&"
export * from "./MovieRequest.vue?vue&type=script&lang=js&"
import style0 from "./MovieRequest.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QCard,QCardSection,QIcon,QCardActions,QBtn} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QCard,QCardSection,QIcon,QCardActions,QBtn})
