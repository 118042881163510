//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    id: { type: Number },
    title: { type: String },
    poster: { type: String },
    loadingApproved: {
      type: Boolean,
      default: false,
    },
    loadingDeclined: { type: Boolean, default: false },
  },
  data() {
    return {};
  },
};
